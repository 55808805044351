import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueCompositionAPI from '@vue/composition-api';
import hooks from '@u3u/vue-hooks';
import moment from 'moment-timezone';

moment.tz.setDefault('America/Argentina/Buenos_Aires');

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(hooks);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
