


















import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useMutations, useState } from '@u3u/vue-hooks';
import TestForm from '@/components/TestForm.vue';
import router from '@/router';
import moment from 'moment';

export default defineComponent({
  components: {
    TestForm,
  },
  setup() {
    const { id } = router.currentRoute.params;
    const { user } = useState('auth', ['user']);
    const created_by = ref({ username: '' });
    const dialog = ref(true);
    const loading = ref(false);
    const message = ref('');
    const testInfo = ref({
      customer: '',
      staff: '',
      shift: '',
      zone: '',
      test_type: new Array<any>(),
      date: '',
      hour: '',
      observations: '',
      created_at: '',
    });

    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);

    const onSubmitTestForm = async (formData: any) => {
      message.value = 'Guardando...';
      loading.value = true;
      try {
        const response = await feathers.service('tests').patch(id, formData, { query: { updateTest: true } });
        const successMessage = `Hecho! Se han actualizado con éxito el test de <strong>${response.last_name.toUpperCase()} ${response.name.toUpperCase()}</strong> 😄`;
        SHOW_SNACKBAR({ message: successMessage, color: 'success' });
        router.push({ name: 'SearchTests' });
      } catch (err) {
        let errorMessage = `Ups! Ocurrió un error al modificar el test 😕`;
        SHOW_SNACKBAR({ message: errorMessage, color: 'error' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    };

    onMounted(async () => {
      message.value = 'Cargando...';
      loading.value = true;
      try {
        let test = await feathers.service('tests').get(id);
        if (test) {
          created_by.value = await feathers.service('users').get(test.created_by);
          test = JSON.parse(JSON.stringify(test).replace(/:null/gi, ':""'));
          test.test_type = (await feathers.service('test-test-types').find({ query: { test: test.id, $limit: -1 } })) as Array<any>;
          testInfo.value = { ...test };
        } else router.push({ name: 'SearchTests' });
      } catch (error) {
        router.push({ name: 'SearchTests' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    });

    return {
      moment,
      user,
      created_by,
      onSubmitTestForm,
      testInfo,
      loading,
      message,
      dialog,
    };
  },
});
