
























import { defineComponent, ref, watch, watchEffect } from '@vue/composition-api';
import { useState, useMutations } from '@u3u/vue-hooks';
import feathers from '@/feathers-client';

export default defineComponent({
  props: {
    outlined: Boolean,
    dense: Boolean,
    icon: String,
    selectedShifts: null,
    selectedStaff: null,
    readonly: Boolean,
    multiple: Boolean,
    chips: Boolean,
    rules: Array,
  },
  setup(props, { emit }) {
    const value = ref(null);
    const { shifts } = useState(['shifts']);
    const { SET_SHIFTS } = useMutations(['SET_SHIFTS']);

    const emitToParent = (event: any) => {
      emit('selectShiftsChanged', event);
    };

    watchEffect(async () => {
      if (props.selectedStaff && !props.readonly) {
        SET_SHIFTS([]);
        const shiftsStaff = (await feathers
          .service('shifts-staff')
          .find({ query: { staff: props.selectedStaff, $limit: -1 } })) as Array<any>;
        const shiftsIds = shiftsStaff.map(({ shift }) => {
          return shift;
        });
        const filteredShifts = (await feathers.service('shifts').find({
          query: {
            id: { $in: shiftsIds },
            $limit: -1,
            $sort: {
              name: 1,
            },
          },
        })) as Array<any>;
        SET_SHIFTS(filteredShifts);
      } else if (!props.readonly) {
        const allShifts = (await feathers.service('shifts').find({ query: { $limit: -1 } })) as Array<any>;
        SET_SHIFTS(allShifts);
      }
    });

    watch(props, () => {
      if (props.selectedShifts) value.value = props.selectedShifts;
    });

    return {
      emitToParent,
      value,
      shifts,
    };
  },
});
