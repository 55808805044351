















import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useMutations } from '@u3u/vue-hooks';
import CustomerForm from '@/components/CustomerForm.vue';
import router from '@/router';
import moment from 'moment';

export default defineComponent({
  components: {
    CustomerForm,
  },
  setup() {
    const { id } = router.currentRoute.params;
    const dialog = ref(true);
    const loading = ref(false);
    const message = ref('');
    const customerInfo = ref({
      name: '',
      last_name: '',
      dni: '',
      date_of_birth: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      country: false,
      details: '',
      phone: '',
      email: '',
      health_insurance: '',
      health_insurance_number: '',
      locality: '',
    });

    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);

    const onSubmitCustomerForm = async (formData: any) => {
      message.value = 'Guardando...';
      loading.value = true;
      try {
        if (formData.date_of_birth === '') formData.date_of_birth = null;
        const response = await feathers.service('customers').patch(id, formData);
        const successMessage = `Hecho! Se han actualizado con éxito los datos de <strong>${response.last_name.toUpperCase()} ${response.name.toUpperCase()}</strong> 😄`;
        SHOW_SNACKBAR({ message: successMessage, color: 'success' });
        router.push({ name: 'SearchCustomers' });
      } catch (err) {
        let errorMessage = `Ups! Ocurrió un error al modificar los datos de <strong>${err.hook.data.last_name.toUpperCase()} ${err.hook.data.name.toUpperCase()}</strong> 😕`;
        if (err.code === 409) errorMessage = 'Ups! El cliente ya existe en la base de datos 😕';
        SHOW_SNACKBAR({ message: errorMessage, color: 'error' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    };

    onMounted(async () => {
      message.value = 'Cargando...';
      loading.value = true;
      try {
        let customer = await feathers.service('customers').get(id, {
          query: {
            $select: [
              'name',
              'last_name',
              'dni',
              'date_of_birth',
              'address',
              'address_number',
              'floor',
              'department',
              'postal_code',
              'country',
              'details',
              'phone',
              'email',
              'health_insurance',
              'health_insurance_number',
              'locality',
            ],
          },
        });
        if (customer) {
          if (customer.date_of_birth) customer.date_of_birth = moment(customer.date_of_birth).format('YYYY-MM-DD');
          customer = JSON.parse(JSON.stringify(customer).replace(/:null/gi, ':""'));
          customerInfo.value = { ...customer };
        } else router.push({ name: 'SearchCustomers' });
      } catch (error) {
        router.push({ name: 'SearchCustomers' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    });

    return {
      onSubmitCustomerForm,
      message,
      customerInfo,
      loading,
      dialog,
    };
  },
});
