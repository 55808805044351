



















































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import feathers from '@/feathers-client';
import moment from 'moment';
import ViewRoster from '@/components/ViewRoster.vue';
import { useActions, useState } from '@u3u/vue-hooks';

export default defineComponent({
  components: {
    ViewRoster,
  },
  setup() {
    const showCompany = ref(false);
    const { staff } = useState(['staff']);
    const { user } = useState('auth', ['user']);
    const { getStaff } = useActions(['getStaff']);
    const date = ref('');
    const staffId = ref(0);
    const unpaid = ref(0);
    const results = ref(0);
    const zonesAndAddresses = ref(new Array<any>());
    const totalTests = ref({
      pcr: 0,
      test_rapido: 0,
      laboratory: 0,
      medic: 0,
    });

    const getUnpaidAndResults = async () => {
      unpaid.value = (await feathers.service('test-test-types').find({ query: { paid: false, $limit: 0 } })).total;
      results.value = (await feathers.service('test-test-types').find({ query: { result: null, $limit: 0 } })).total;
    };

    const getTotalTests = async () => {
      const dbData = await feathers
        .service('test-test-types')
        .find({ query: { getTotalTests: true, date: date.value, showCompany: showCompany.value } });
      totalTests.value = {
        pcr: 0,
        test_rapido: 0,
        laboratory: 0,
        medic: 0,
      };
      dbData.map((testType: any) => {
        if (testType.name.toLowerCase() == 'pcr') totalTests.value.pcr = testType.count;
        else if (testType.name.toLowerCase() == 'test rápido') totalTests.value.test_rapido = testType.count;
        else if (testType.name.toLowerCase() == 'laboratorio') totalTests.value.laboratory = testType.count;
        else if (testType.name.toLowerCase() == 'clinica medica') totalTests.value.medic = testType.count;
      });
    };

    const getZonesAndAddresses = async () => {
      zonesAndAddresses.value = await feathers
        .service('tests')
        .find({ query: { getZonesAndAddresses: true, date: date.value, showCompany: showCompany.value } });
    };

    watch(date, async () => {
      if (date.value) {
        await getTotalTests();
        await getZonesAndAddresses();
      }
    });

    onMounted(async () => {
      await getUnpaidAndResults();
      await getStaff();
      await staff.value.unshift({ id: -1, name: 'TODOS' });
      staffId.value = -1;
      date.value = moment().format('YYYY-MM-DD');
    });

    const onShowCompanyChange = async (showCompanyChild: boolean) => {
      showCompany.value = showCompanyChild;
      await getTotalTests();
      await getZonesAndAddresses();
    };

    return {
      user,
      date,
      unpaid,
      results,
      staffId,
      moment,
      staff,
      totalTests,
      zonesAndAddresses,
      onShowCompanyChange,
    };
  },
});
