





















import router from '@/router';
import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  props: {
    viewing: Boolean,
    noBack: Boolean,
  },
  setup(props, { emit }) {
    const dialog = ref(false);

    const goBack = () => {
      emit('goBack');
      if (!props.noBack) router.back();
    };

    const onClick = () => {
      if (props.viewing) {
        goBack();
      } else dialog.value = true;
    };
    return {
      dialog,
      onClick,
      goBack,
    };
  },
});
