




























































































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useState } from '@u3u/vue-hooks';
import router from '@/router';
import Localities from '@/components/Localities.vue';
import ViewCustomer from '@/components/ViewCustomer.vue';

export default defineComponent({
  props: {},
  components: {
    ViewCustomer,
    Localities,
  },
  setup(props, { emit }) {
    const { user } = useState('auth', ['user']);
    const customers = ref([]);
    const deleteDialog = ref(false);
    const customerToDelete = ref(null);
    const valid = ref(false);
    const form = ref();
    const loading = ref(false);
    const viewDialog = ref(false);
    const selectedCustomer = ref('');
    const totalCustomers = ref(0);
    const options = ref();
    const searchCustomer = ref({
      name: '',
      last_name: '',
      dni: '',
      address: '',
      postal_code: '',
      locality: '',
    });

    const headers = ref([
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Apellido', value: 'last_name' },
      { text: 'Número de documento', value: 'dni' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]);
    const numberRule = [
      (value: string) => {
        const pattern = /^(|[0-9]*)$/;
        return pattern.test(value) || 'Solo números';
      },
    ];

    const onSelectLocalityChanged = (locality: any) => {
      searchCustomer.value.locality = locality;
    };

    const updateCustomer = (item: any) => {
      router.push({ name: 'UpdateCustomer', params: { id: item.id } });
    };

    const viewCustomer = (item: any) => {
      selectedCustomer.value = item.id;
      viewDialog.value = true;
    };

    const deleteCustomer = async () => {
      loading.value = true;
      await feathers.service('customers').remove(customerToDelete.value);
      deleteDialog.value = false;
      customerToDelete.value = null;
      await getCustomers();
      loading.value = false;
    };

    const getCustomers = async () => {
      loading.value = true;
      const formValid = form.value.validate();
      if (formValid) {
        try {
          const customersData = await feathers.service('customers').find({
            query: {
              searchCustomers: true,
              ...searchCustomer.value,
              ...options.value,
            },
          });
          customersData.data.forEach((a: any) => {
            a.name = a.name.toUpperCase();
            a.last_name = a.last_name.toUpperCase();
          });
          totalCustomers.value = customersData.total;
          customers.value = customersData.data;
        } catch (err) {}
      }
      loading.value = false;
    };

    watch(options, async (newOptions: any, oldOptions: any) => {
      if (oldOptions) await getCustomers();
    });

    const emitToParent = (item: any) => {
      emit('customerSelected', item);
    };

    return {
      onSelectLocalityChanged,
      headers,
      customers,
      getCustomers,
      valid,
      form,
      loading,
      searchCustomer,
      updateCustomer,
      viewCustomer,
      numberRule,
      emitToParent,
      selectedCustomer,
      viewDialog,
      totalCustomers,
      options,
      user,
      deleteDialog,
      deleteCustomer,
      customerToDelete,
    };
  },
});
