































































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import feathers from '@/feathers-client';
import router from '@/router';
import Zones from '@/components/Zones.vue';
import ViewStaff from '@/components/ViewStaff.vue';

export default defineComponent({
  props: {
    appointment: Boolean,
  },
  components: {
    ViewStaff,
    Zones,
  },
  setup(props, { emit }) {
    const staff = ref([]);
    const valid = ref(false);
    const form = ref();
    const loading = ref(false);
    const viewDialog = ref(false);
    const selectedStaff = ref('');
    const totalStaff = ref(0);
    const options = ref();
    const searchStaff = ref({
      name: '',
      last_name: '',
      zone: '',
      enabled: '',
    });

    const headers = ref([
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Apellido', value: 'last_name' },
      { text: 'Zonas', value: 'zones', sortable: false },
      { text: 'Horarios', value: 'shifts', sortable: false },
      { text: 'Habilitado', value: 'enabled' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]);
    const numberRule = [
      (value: string) => {
        const pattern = /^(|[0-9]*)$/;
        return pattern.test(value) || 'Solo números';
      },
    ];

    const onSelectZoneChanged = (zone: any) => {
      searchStaff.value.zone = zone;
    };

    const updateStaff = (item: any) => {
      router.push({ name: 'UpdateStaff', params: { id: item.id } });
    };

    const viewStaff = (item: any) => {
      selectedStaff.value = item.id;
      viewDialog.value = true;
    };

    const disableStaff = async (item: any) => {
      await feathers.service('staff').patch(item.id, { enabled: false });
      await getStaff();
    };

    const enableStaff = async (item: any) => {
      await feathers.service('staff').patch(item.id, { enabled: true });
      await getStaff();
    };

    const getStaff = async () => {
      loading.value = true;
      const formValid = form.value.validate();
      if (formValid) {
        try {
          const staffData = await feathers.service('staff').find({
            query: {
              searchStaff: true,
              ...searchStaff.value,
              ...options.value,
            },
          });
          staffData.data.forEach((a: any) => {
            a.name = a.name.toUpperCase();
            a.last_name = a.last_name.toUpperCase();
          });
          totalStaff.value = staffData.total;
          staff.value = staffData.data;
        } catch (err) {}
      }
      loading.value = false;
    };

    watch(options, async (newOptions: any, oldOptions: any) => {
      if (oldOptions) await getStaff();
    });

    const emitToParent = (item: any) => {
      emit('staffSelected', item);
    };

    return {
      onSelectZoneChanged,
      headers,
      staff,
      getStaff,
      valid,
      form,
      loading,
      searchStaff,
      updateStaff,
      disableStaff,
      enableStaff,
      viewStaff,
      numberRule,
      emitToParent,
      selectedStaff,
      viewDialog,
      totalStaff,
      options,
    };
  },
});
