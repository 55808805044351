















import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useMutations } from '@u3u/vue-hooks';
import StaffForm from '@/components/StaffForm.vue';
import router from '@/router';

export default defineComponent({
  components: {
    StaffForm,
  },
  setup() {
    const { id } = router.currentRoute.params;
    const dialog = ref(true);
    const loading = ref(false);
    const message = ref('');
    const staffInfo = ref({
      name: '',
      last_name: '',
      locality: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      phone: '',
      email: '',
      shifts: '',
      zones: '',
    });

    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);

    const onSubmitStaffForm = async (formData: any) => {
      message.value = 'Guardando...';
      loading.value = true;
      try {
        const response = await feathers.service('staff').patch(id, { updateStaff: true, ...formData });
        const successMessage = `Hecho! Se han actualizado con éxito los datos de <strong>${response.last_name.toUpperCase()} ${response.name.toUpperCase()}</strong> 😄`;
        SHOW_SNACKBAR({ message: successMessage, color: 'success' });
        router.push({ name: 'SearchStaff' });
      } catch (err) {
        let errorMessage = `Ups! Ocurrió un error al modificar los datos de <strong>${err.hook.data.last_name.toUpperCase()} ${err.hook.data.name.toUpperCase()}</strong> 😕`;
        SHOW_SNACKBAR({ message: errorMessage, color: 'error' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    };

    onMounted(async () => {
      message.value = 'Cargando...';
      loading.value = true;
      try {
        let staff = await feathers.service('staff').get(id, {
          query: {
            $select: ['name', 'last_name', 'address', 'address_number', 'floor', 'department', 'postal_code', 'phone', 'email', 'locality'],
          },
        });

        if (staff) {
          const zones = (await feathers.service('zones-staff').find({ query: { staff: id, $select: ['zone'], $limit: -1 } })) as Array<any>;
          const shifts = (await feathers
            .service('shifts-staff')
            .find({ query: { staff: id, $select: ['shift'], $limit: -1 } })) as Array<any>;

          staff.zones = zones.map((zone: any) => {
            return zone.zone;
          });
          staff.shifts = shifts.map((shift: any) => {
            return shift.shift;
          });

          staff = JSON.parse(JSON.stringify(staff).replace(/:null/gi, ':""'));
          staffInfo.value = { ...staff };
        } else router.push({ name: 'SearchStaff' });
      } catch (error) {
        router.push({ name: 'SearchStaff' });
      } finally {
        loading.value = false;
        message.value = '';
      }
    });

    return {
      loading,
      dialog,
      message,
      onSubmitStaffForm,
      staffInfo,
    };
  },
});
