var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.getStaff.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","dense":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","to":{ name: 'CreateStaff' },"dark":"","absolute":"","bottom":"","right":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo staff")])])]},proxy:true}])},[_c('v-toolbar-title',[_vm._v("Buscar Staff")])],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Nombre","type":"text","name":"name","id":"name"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getStaff.apply(null, arguments)}},model:{value:(_vm.searchStaff.name),callback:function ($$v) {_vm.$set(_vm.searchStaff, "name", $$v)},expression:"searchStaff.name"}}),_c('v-text-field',{attrs:{"id":"last_name","label":"Apellido","name":"last_name","type":"text"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getStaff.apply(null, arguments)}},model:{value:(_vm.searchStaff.last_name),callback:function ($$v) {_vm.$set(_vm.searchStaff, "last_name", $$v)},expression:"searchStaff.last_name"}}),_c('Zones',{attrs:{"dense":false,"icon":undefined,"outlined":false},on:{"selectZoneChanged":_vm.onSelectZoneChanged}}),_c('v-autocomplete',{attrs:{"items":[
                { id: 1, name: 'Si' },
                { id: 0, name: 'No' } ],"item-value":"id","item-text":"name","label":"Habilitado","name":"enabled","id":"enabled","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getStaff.apply(null, arguments)}},model:{value:(_vm.searchStaff.enabled),callback:function ($$v) {_vm.$set(_vm.searchStaff, "enabled", $$v)},expression:"searchStaff.enabled"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success","type":"submit"}},[_vm._v("Buscar")])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Resultado")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.staff,"server-items-length":_vm.totalStaff,"options":_vm.options,"loading":_vm.loading,"sort-by":"name","sort-desc":"","loading-text":"Cargando... por favor espere"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.zones",fn:function(ref){
              var item = ref.item;
return _vm._l((item.zones),function(zone){return _c('v-chip',{key:zone.id,staticClass:"mx-1"},[_vm._v(_vm._s(zone.name))])})}},{key:"item.shifts",fn:function(ref){
              var item = ref.item;
return _vm._l((item.shifts),function(shift){return _c('v-chip',{key:shift.id,staticClass:"mx-1"},[_vm._v(_vm._s(shift.name))])})}},{key:"item.enabled",fn:function(ref){
              var item = ref.item;
return [(item.enabled)?_c('span',[_vm._v("Si")]):_c('span',[_vm._v("No")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":function($event){return _vm.viewStaff(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-search-outline")])]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",on:{"click":function($event){return _vm.updateStaff(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(item.enabled)?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.disableStaff(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Deshabilitar")])]):_vm._e(),(!item.enabled)?_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.enableStaff(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Habilitar")])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Ver staff")]),_c('v-card-text',[(_vm.viewDialog)?_c('ViewStaff',{attrs:{"staffId":_vm.selectedStaff}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }