
















import { defineComponent, ref } from '@vue/composition-api';
import TestForm from '@/components/TestForm.vue';
import { useMutations } from '@u3u/vue-hooks';
import router from '@/router';
import feathers from '@/feathers-client';

export default defineComponent({
  components: {
    TestForm,
  },
  setup() {
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const loading = ref(false);
    const dialog = ref(true);
    const testInfo = ref({
      customer: '',
      staff: '',
      shift: '',
      zone: '',
      test_type: new Array<any>(),
      date: '',
      hour: '',
      observations: '',
    });

    const onSubmitTestForm = async (formData: any) => {
      loading.value = true;
      try {
        const response = await feathers.service('tests').create(formData, { query: { createTest: true } });
        const successText = `Hecho! Se han dado de alta con éxito el test de <strong>${response.last_name.toUpperCase()} ${response.name.toUpperCase()}</strong> 😄`;
        SHOW_SNACKBAR({ message: successText, color: 'success' });
        router.replace({ name: 'SearchTests' });
      } catch (err) {
        let errorText = `Ups! Ocurrió un error al dar de alta el test 😕`;
        SHOW_SNACKBAR({ message: errorText, color: 'error' });
      } finally {
        loading.value = false;
      }
    };

    return {
      onSubmitTestForm,
      testInfo,
      loading,
      dialog,
    };
  },
});
