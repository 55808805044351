







































import { defineComponent, computed, ref, onMounted, watch } from '@vue/composition-api';
import { useActions, useState, useMutations } from '@u3u/vue-hooks';
import Navbar from '@/components/Navbar.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
  },
  setup() {
    const { reAuth } = useActions('auth', ['reAuth']);
    const { setEstablishment, getLocalities, getZones, getShifts, getTestTypes } = useActions([
      'setEstablishment',
      'getLocalities',
      'getZones',
      'getShifts',
      'getTestTypes',
    ]);
    const { HIDE_SNACKBAR } = useMutations(['HIDE_SNACKBAR']);
    const { snackbar, snackbarInfo, establishment, zones, shifts, localities, testTypes } = useState([
      'snackbar',
      'snackbarInfo',
      'establishment',
      'zones',
      'shifts',
      'localities',
      'testTypes',
    ]);
    const { user } = useState('auth', ['user']);
    const dialog = ref(true);

    const snackbarModel = computed({
      get: () => {
        return snackbar.value;
      },
      set: () => {
        HIDE_SNACKBAR();
      },
    });

    watch(user, async () => {
      if (user.value) {
        await getLocalities();
        await getZones();
        await getShifts();
        await getTestTypes();
      }
    });

    onMounted(async () => {
      await reAuth();
      await setEstablishment();
    });

    return {
      snackbarInfo,
      snackbarModel,
      user,
      establishment,
      zones,
      dialog,
      shifts,
      localities,
      testTypes,
    };
  },
});
