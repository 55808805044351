







































































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import GoBackButton from '@/components/GoBackButton.vue';
import feathers from '@/feathers-client';
import moment from 'moment';
import Localities from './Localities.vue';
import Zones from './Zones.vue';
import Shifts from './Shifts.vue';

export default defineComponent({
  props: {
    staffInfo: null,
    viewing: Boolean,
  },
  components: {
    GoBackButton,
    Localities,
    Zones,
    Shifts,
  },
  setup(props, { emit }) {
    const form = ref();
    const valid = ref(false);
    const loading = ref(false);
    const selectedLocality = ref(0);
    const selectedZones = ref(null);
    const selectedShifts = ref(null);
    const numberRule = [
      (value: string) => !!value || 'Este campo es obligatorio',
      (value: string) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || 'Solo números';
      },
    ];
    const dniRule = [
      (value: string) => !!value || 'Este campo es obligatorio',
      (value: string) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || 'Solo números';
      },
      (value: string) => value.length <= 8 || 'Máximo 8 caracteres',
    ];

    const emailRule = [
      (value: string) => {
        const pattern =
          /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        return pattern.test(value) || 'Formato de correo electrónico inválido';
      },
    ];
    const requiredRule = [(v: string) => !!v || 'Este campo es obligatorio'];

    const emitToParent = () => {
      loading.value = true;
      const formValid = form.value.validate();
      try {
        if (formValid) {
          const formData = {
            ...props.staffInfo,
          };
          emit('submitStaffForm', formData);
        }
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    const onSelectLocalityChanged = (locality: any) => {
      props.staffInfo.locality = locality;
    };
    const onSelectZonesChanged = (zones: any) => {
      props.staffInfo.zones = zones;
    };
    const onSelectShiftsChanged = (shifts: any) => {
      props.staffInfo.shifts = shifts;
    };

    watch(props, () => {
      if (props.staffInfo.locality) selectedLocality.value = props.staffInfo.locality;
      if (props.staffInfo.zones) selectedZones.value = props.staffInfo.zones;
      if (props.staffInfo.shifts) selectedShifts.value = props.staffInfo.shifts;
    });

    return {
      requiredRule,
      numberRule,
      dniRule,
      emailRule,
      form,
      valid,
      emitToParent,
      onSelectLocalityChanged,
      onSelectZonesChanged,
      onSelectShiftsChanged,
      loading,
      moment,
      selectedLocality,
      selectedZones,
      selectedShifts,
    };
  },
});
