

































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import VueHtml2pdf from 'vue-html2pdf';
import feathers from '@/feathers-client';
import moment from 'moment';

export default defineComponent({
  props: {
    date: null,
    healthInsurance: null,
  },
  components: { VueHtml2pdf },
  setup(props, { emit }) {
    const htmlToPrint = ref();
    const title = ref('');
    const listInfo = ref(
      new Array<{
        dni: string;
        lastName: string;
        name: string;
        result: null;
        testType: string;
        protocol: string;
      }>()
    );
    if (props.date.length === 1) {
      title.value = `Protocolos del ${moment(props.date[0]).format('DD-MM-YYYY')}`;
    } else if (props.date.length > 1) {
      title.value = `Protocolos del ${moment(props.date[0]).format('DD-MM-YYYY')} al ${moment(props.date[1]).format('DD-MM-YYYY')}`;
    }

    onMounted(async () => {
      let printedData = false;
      if (props.date) {
        listInfo.value = await feathers
          .service('test-test-types')
          .find({ query: { searchForProtocolsList: true, date: props.date, healthInsurance: props.healthInsurance } });
        if (listInfo.value.length) {
          await htmlToPrint.value.generatePdf();
          printedData = true;
        }
      }
      emit('printed', printedData);
    });

    return {
      htmlToPrint,
      moment,
      title,
      listInfo,
    };
  },
});
