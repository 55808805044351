






































































































































































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import moment from 'moment';
import { useMutations, useState } from '@u3u/vue-hooks';
import router from '@/router';
import GoBackButton from '@/components/GoBackButton.vue';
import Protocol from '@/components/Protocol.vue';

export default defineComponent({
  props: {},
  components: {
    GoBackButton,
    Protocol,
  },
  setup(props) {
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const { user } = useState('auth', ['user']);
    const dialog = ref(true);
    const loading = ref(false);
    const printDialog = ref(false);
    const protocolList = ref(
      new Array<{
        protocol: string;
        date: string;
        hour: string;
        dni: string;
        dateOfBirth: string;
        fullName: string;
        testType: string;
        result: string;
        updatedAt: string;
        customSelect: boolean;
      }>()
    );
    const protocolInfo = ref({
      protocol: '',
      date: '',
      hour: '',
      dni: '',
      dateOfBirth: '',
      fullName: '',
      testType: '',
      result: '',
      updatedAt: '',
      customSelect: false,
    });
    const { testTypes } = useState(['testTypes']);
    const commonInfo = ref({
      address: '',
      address_number: '',
      full_address: '',
      floor: '',
      department: '',
      postal_code: '',
      locality: '',
      zone: '',
      email: '',
      country: '',
      details: '',
      observations: '',
      completed: false,
      pcr_count: 0,
      test_rapido_count: 0,
      laboratory_count: 0,
      medic_count: 0,
      phone: '',
      staff_last_name: '',
      staff_name: '',
      created_by: '',
      created_at: '',
    });
    const persons = ref(
      new Array<{
        id: string;
        name: string;
        last_name: string;
        dni: string;
        date_of_birth: string;
        selectedTestTypes: Array<number>;
        test_types: Array<{
          id: string;
          test_type: string;
          name: string;
          result: any;
          paid: any;
          details: string;
          custom_select: boolean;
          custom_date: string;
          custom_hour: string;
          updated_at: string;
          protocol: string;
        }>;
      }>()
    );

    const getInitData = async () => {
      if (!router.currentRoute.params || !router.currentRoute.params.appointment) router.back();
      else {
        const {
          address,
          address_number,
          floor,
          department,
          postal_code,
          locality,
          zone,
          email,
          country,
          details,
          observations,
          full_address,
          completed,
          pcr_count,
          test_rapido_count,
          laboratory_count,
          medic_count,
          phone,
          staff_last_name,
          staff_name,
          created_by,
          created_at,
        } = (router.currentRoute.params.appointment as unknown as Array<any>)[0];

        commonInfo.value = {
          address,
          address_number,
          full_address,
          floor,
          department,
          postal_code,
          locality,
          zone,
          email,
          country,
          details,
          observations,
          completed,
          pcr_count,
          test_rapido_count,
          laboratory_count,
          medic_count,
          phone,
          staff_last_name,
          staff_name,
          created_by,
          created_at,
        };

        const personsInfo = router.currentRoute.params.appointment as unknown as Array<any>;
        persons.value = [];
        personsInfo.forEach((person) => {
          const { id, name, last_name, dni, date_of_birth } = person;
          persons.value.push({
            id,
            name,
            last_name,
            dni,
            date_of_birth,
            test_types: person.test_types,
            selectedTestTypes: person.test_types.map((testType) => {
              return { id: testType.test_type, name: testType.name };
            }),
          });
        });
      }
    };

    onMounted(async () => {
      await getInitData();
    });

    const onChangeTestType = (testTypes: any, index?: any) => {
      if (index != undefined) {
        persons.value[index].test_types = testTypes.map((testType: any) => {
          const existing = persons.value[index].test_types.findIndex((i) => i.test_type == testType.id);
          return {
            test_type: testType.id,
            name: testType.name,
            id: existing != -1 ? persons.value[index].test_types[existing].id : null,
            result: existing != -1 ? persons.value[index].test_types[existing].result : null,
            paid: existing != -1 ? persons.value[index].test_types[existing].paid : false,
            details: existing != -1 ? persons.value[index].test_types[existing].details : null,
            custom_select: existing != -1 ? persons.value[index].test_types[existing].custom_select : null,
            custom_date: existing != -1 ? persons.value[index].test_types[existing].custom_date : null,
            custom_hour: existing != -1 ? persons.value[index].test_types[existing].custom_hour : null,
            updated_at: existing != -1 ? persons.value[index].test_types[existing].updated_at : null,
            protocol: existing != -1 ? persons.value[index].test_types[existing].protocol : null,
          };
        });
      }
    };

    const goToAddress = () => {
      window.open(
        `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${commonInfo.value.address}+${
          commonInfo.value.address_number
        }${commonInfo.value.locality ? `+${commonInfo.value.locality}` : ''}`
      );
    };

    const onSaveTests = async () => {
      loading.value = true;
      try {
        await feathers.service('test-test-types').create(persons.value, { query: { createInMoreInfo: true } });
        const newStorage = new Array<any>();
        let pcr_count = 0;
        let test_rapido_count = 0;
        let laboratory_count = 0;
        let medic_count = 0;

        persons.value.forEach((person) => {
          person.test_types.forEach((testType) => {
            if (testType.name.toLowerCase() == 'pcr') pcr_count++;
            else if (testType.name.toLowerCase() == 'test rápido') test_rapido_count++;
            else if (testType.name.toLowerCase() == 'laboratorio') laboratory_count++;
            else if (testType.name.toLowerCase() == 'clinica medica') medic_count++;
          });
        });

        persons.value.forEach((person) => {
          newStorage.push({
            ...commonInfo.value,
            ...person,
            pcr_count,
            test_rapido_count,
            laboratory_count,
            medic_count,
          });
        });

        const groupedMorning = JSON.parse(localStorage.getItem('groupedMorning')!);
        const groupedAfternoon = JSON.parse(localStorage.getItem('groupedAfternoon')!);

        for await (const appointment of groupedMorning) {
          if (appointment[0] == commonInfo.value.full_address) {
            for await (const person of newStorage) {
              const testTypes = (await feathers.service('test-test-types').find({ query: { test: person.id, $limit: -1 } })) as Array<any>;
              person.test_types = testTypes.map((testType) => {
                return {
                  custom_date: null,
                  custom_hour: null,
                  custom_select: null,
                  name: testType.test_type == 2 ? 'PCR' : 'Test rápido',
                  ...testType,
                };
              });
            }
            appointment[1] = newStorage;
            router.currentRoute.params.appointment = newStorage as unknown as string;
          }
        }

        for await (const appointment of groupedAfternoon) {
          if (appointment[0] == commonInfo.value.full_address) {
            for await (const person of newStorage) {
              const testTypes = (await feathers.service('test-test-types').find({ query: { test: person.id, $limit: -1 } })) as Array<any>;
              person.test_types = testTypes.map((testType) => {
                return {
                  custom_date: null,
                  custom_hour: null,
                  custom_select: null,
                  name: testType.test_type == 2 ? 'PCR' : 'Test rápido',
                  ...testType,
                };
              });
            }
            appointment[1] = newStorage;
            router.currentRoute.params.appointment = newStorage as unknown as string;
          }
        }

        localStorage.setItem('groupedMorning', JSON.stringify(groupedMorning));
        localStorage.setItem('groupedAfternoon', JSON.stringify(groupedAfternoon));

        await getInitData();
        SHOW_SNACKBAR({ message: 'Se han guardado los cambios con éxito 😄', color: 'success' });
      } catch (error) {
        SHOW_SNACKBAR({ message: 'Ocurrio un error al guardar los cambios 😕', color: 'error' });
      } finally {
        loading.value = false;
      }
    };

    const onChangeStatusAppointment = async (newStatus: boolean) => {
      loading.value = true;
      try {
        for await (const person of persons.value) {
          await feathers.service('tests').patch(person.id, { completed: newStatus });
        }
        if (newStatus) commonInfo.value.completed = true;
        else commonInfo.value.completed = false;
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    const onPrintProtocol = (person: any, index: number) => {
      protocolInfo.value = {
        protocol: person.test_types[index].protocol,
        dni: person.dni,
        dateOfBirth: person.date_of_birth,
        fullName: `${person.last_name.toUpperCase()} ${person.name.toUpperCase()}`,
        testType: person.test_types[index].name,
        result: person.test_types[index].result,
        date: person.test_types[index].custom_date,
        hour: person.test_types[index].custom_hour,
        updatedAt: person.test_types[index].updated_at,
        customSelect: person.test_types[index].custom_select,
      };
      printDialog.value = true;
    };

    const onPrintAll = async () => {
      for await (const person of persons.value) {
        for await (const testType of person.test_types) {
          if (testType.protocol && testType.result != null) {
            protocolList.value.push({
              protocol: testType.protocol,
              dni: person.dni,
              dateOfBirth: person.date_of_birth,
              fullName: `${person.last_name.toUpperCase()} ${person.name.toUpperCase()}`,
              testType: testType.name,
              result: testType.result,
              date: testType.custom_date,
              hour: testType.custom_hour,
              updatedAt: testType.updated_at,
              customSelect: testType.custom_select,
            });
          }
        }
      }
      printDialog.value = true;
    };

    const onProtocolPrinted = (printedData: boolean) => {
      protocolInfo.value = { ...protocolInfo.value, protocol: '' };
      protocolList.value = [];
      printDialog.value = false;
      if (!printedData) SHOW_SNACKBAR({ message: 'Ocurrio un error al descargar el protocolo 😕', color: 'error' });
    };

    return {
      user,
      moment,
      loading,
      dialog,
      commonInfo,
      persons,
      testTypes,
      goToAddress,
      onChangeTestType,
      onSaveTests,
      onChangeStatusAppointment,
      onProtocolPrinted,
      printDialog,
      protocolInfo,
      protocolList,
      onPrintProtocol,
      onPrintAll,
    };
  },
});
