















import { defineComponent, ref } from '@vue/composition-api';
import CustomerForm from '@/components/CustomerForm.vue';
import { useMutations } from '@u3u/vue-hooks';
import router from '@/router';
import feathers from '@/feathers-client';

export default defineComponent({
  components: {
    CustomerForm,
  },
  setup() {
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const dialog = ref(true);
    const loading = ref(false);
    const customerInfo = ref({
      name: '',
      last_name: '',
      dni: '',
      date_of_birth: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      country: false,
      details: '',
      phone: '',
      email: '',
      health_insurance: '',
      health_insurance_number: '',
      locality: '',
    });

    const onSubmitCustomerForm = async (formData: any) => {
      loading.value = true;
      try {
        if (formData.date_of_birth === '') formData.date_of_birth = null;
        const response = await feathers.service('customers').create(formData);
        const successText = `Hecho! Se han dado de alta con éxito el cliente <strong>${response.last_name.toUpperCase()} ${response.name.toUpperCase()}</strong> 😄`;
        SHOW_SNACKBAR({ message: successText, color: 'success' });
        router.replace({ name: 'SearchCustomers' });
      } catch (err) {
        let errorText = `Ups! Ocurrió un error al dar de alta el cliente <strong>${err.hook.data.last_name.toUpperCase()} ${err.hook.data.name.toUpperCase()}</strong> 😕`;
        if (err.code === 409) errorText = 'Ups! El cliente ya existe en la base de datos 😕';
        SHOW_SNACKBAR({ message: errorText, color: 'error' });
      } finally {
        loading.value = false;
      }
    };

    return {
      onSubmitCustomerForm,
      customerInfo,
      dialog,
      loading,
    };
  },
});
