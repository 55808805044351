





















































































import { defineComponent, ref, watchEffect } from '@vue/composition-api';
import router from '@/router';
import { useActions, useState } from '@u3u/vue-hooks';

export default defineComponent({
  name: 'Login',
  setup() {
    const valid = ref(false);
    const form = ref();
    const loading = ref(false);
    const error = ref('');
    const { login, changeTemporaryPassword } = useActions('auth', ['login', 'changeTemporaryPassword']);
    const { user } = useState('auth', ['user']);
    const { establishment } = useState(['establishment']);
    const userInfo = ref({
      username: '',
      password: '',
    });

    const validate = async () => {
      loading.value = true;
      const formValid = form.value.validate();
      if (formValid) {
        try {
          if (user.value && user.value.temporary_password) {
            await changeTemporaryPassword(userInfo.value.password);
          } else {
            await login(userInfo.value);
          }
        } catch (err: any) {
          if (err.code === 401) error.value = 'Los datos ingresados no son correctos.';
          if (err.code === 408) error.value = 'Límite de intentos excedido, vuelva a intentar en 10 minutos.';
        }
      }
      loading.value = false;
    };

    watchEffect(() => {
      if (user.value && user.value.id && !user.value.temporary_password) {
        if (user.value.staff) {
          router.push({ name: 'HomeStaff' });
        } else {
          router.push({ name: 'Home' });
        }
      }
    });

    return {
      valid,
      form,
      userInfo,
      loading,
      error,
      validate,
      establishment,
      user,
    };
  },
});
