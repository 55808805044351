var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.user && !_vm.user.temporary_password)?_c('Navbar'):_vm._e(),(
      _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'VerifyProtocol' &&
      (!_vm.establishment.logo || !_vm.localities || !_vm.zones || !_vm.shifts || !_vm.testTypes)
    )?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"transition":false,"persistent":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"primary"}})],1)],1)],1)],1):_vm._e(),(
      (_vm.establishment.logo && _vm.user && _vm.zones && _vm.shifts && _vm.localities && _vm.testTypes) ||
      _vm.$route.name === 'Login' ||
      _vm.$route.name === 'VerifyProtocol'
    )?_c('v-main',[_c('router-view')],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":"10000","multi-line":true,"color":_vm.snackbarInfo.color,"app":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbarModel = false}}},'v-btn',attrs,false),[_vm._v("Cerrar")])]}}]),model:{value:(_vm.snackbarModel),callback:function ($$v) {_vm.snackbarModel=$$v},expression:"snackbarModel"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.snackbarInfo.message)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }