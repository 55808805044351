









import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import TestForm from '@/components/TestForm.vue';
import moment from 'moment';
import { useState } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    testId: Number,
  },
  components: {
    TestForm,
  },
  setup(props) {
    const { user } = useState('auth', ['user']);
    const created_by = ref({ username: '' });
    const testInfo = ref({
      customer: '',
      staff: '',
      shift: '',
      zone: '',
      test_type: new Array<any>(),
      date: '',
      observations: '',
      created_at: '',
    });

    const getTestInfo = async () => {
      try {
        let test = await feathers.service('tests').get(props.testId);
        if (test) {
          created_by.value = await feathers.service('users').get(test.created_by);
          test = JSON.parse(JSON.stringify(test).replace(/:null/gi, ':""'));
          test.test_type = (await feathers.service('test-test-types').find({ query: { test: test.id, $limit: -1 } })) as Array<any>;
          testInfo.value = { ...test };
        }
      } catch (error) {}
    };

    onMounted(async () => {
      await getTestInfo();
    });

    return {
      moment,
      created_by,
      user,
      testInfo,
    };
  },
});
