import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import feathers from '@/feathers-client';
import Home from '../views/Home.vue';
import HomeStaff from '../views/HomeStaff.vue';
import Login from '@/views/Login.vue';
import Customers from '@/views/Customers.vue';
import SearchCustomers from '@/components/SearchCustomers.vue';
import CreateCustomer from '@/components/CreateCustomer.vue';
import UpdateCustomer from '@/components/UpdateCustomer.vue';
import Staff from '@/views/Staff.vue';
import SearchStaff from '@/components/SearchStaff.vue';
import CreateStaff from '@/components/CreateStaff.vue';
import UpdateStaff from '@/components/UpdateStaff.vue';
import Tests from '@/views/Tests.vue';
import CreateTest from '@/components/CreateTest.vue';
import SearchTests from '@/components/SearchTests.vue';
import UpdateTest from '@/components/UpdateTest.vue';
import ViewRosterMoreInfo from '@/components/ViewRosterMoreInfo.vue';
import HealthInsurances from '@/views/HealthInsurances.vue';
import VerifyProtocol from '@/components/VerifyProtocol.vue';
import { watchEffect } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '*', component: Login },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true, requiresManagement: true },
  },
  {
    path: '/home-staff',
    name: 'HomeStaff',
    component: HomeStaff,
    meta: { requiresAuth: true, requiresStaff: true },
  },
  {
    path: '/clientes',
    component: Customers,
    meta: { requiresAuth: true, requiresManagement: true },
    children: [
      {
        path: '',
        name: 'SearchCustomers',
        component: SearchCustomers,
      },
      {
        path: 'nuevo',
        name: 'CreateCustomer',
        component: CreateCustomer,
      },
      {
        path: 'editar',
        name: 'UpdateCustomer',
        component: UpdateCustomer,
      },
    ],
  },
  {
    path: '/staff',
    component: Staff,
    meta: { requiresAuth: true, requiresManagement: true, requiresExternal: true },
    children: [
      {
        path: '',
        name: 'SearchStaff',
        component: SearchStaff,
      },
      {
        path: 'nuevo',
        name: 'CreateStaff',
        component: CreateStaff,
      },
      {
        path: 'editar',
        name: 'UpdateStaff',
        component: UpdateStaff,
      },
    ],
  },
  {
    path: '/tests',
    component: Tests,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'SearchTests',
        component: SearchTests,
        meta: { requiresManagement: true },
      },
      {
        path: 'nuevo',
        name: 'CreateTest',
        component: CreateTest,
        meta: { requiresManagement: true },
      },
      {
        path: 'editar',
        name: 'UpdateTest',
        component: UpdateTest,
        meta: { requiresManagement: true },
      },
      {
        path: 'mas-info',
        name: 'ViewRosterMoreInfo',
        component: ViewRosterMoreInfo,
      },
    ],
  },
  {
    path: '/servicios-salud',
    component: HealthInsurances,
    name: 'HealthInsurances',
    meta: { requiresAuth: true, requiresManagement: true, requiresExternal: true },
  },
  {
    path: '/verify-protocol/:uuid',
    component: VerifyProtocol,
    name: 'VerifyProtocol',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = await feathers.authentication.getAccessToken();
    if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' });
    else next();
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresStaff)) {
    const { user } = useState('auth', ['user']);
    watchEffect(() => {
      if (to.name !== 'Login' && user.value && !user.value.staff) {
        next({ name: 'Login' });
      } else next();
    });
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresManagement)) {
    const { user } = useState('auth', ['user']);
    watchEffect(() => {
      if (to.name !== 'Login' && user.value && !user.value.management) {
        next({ name: 'Login' });
      } else next();
    });
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresInternal)) {
    const { user } = useState('auth', ['user']);
    watchEffect(() => {
      if (to.name !== 'Login' && user.value && !user.value.external) {
        next({ name: 'Login' });
      } else next();
    });
  } else {
    next();
  }
});

export default router;
