














































































import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { useMutations, useState } from '@u3u/vue-hooks';
import router from '@/router';
import feathers from '@/feathers-client';

export default defineComponent({
  setup() {
    const { establishment } = useState(['establishment']);
    const protocolInfo = ref();

    onMounted(async () => {
      const uuid = router.currentRoute.params.uuid;
      protocolInfo.value = (await feathers.service('qr-codes').find({ query: { uuid, $limit: -1 } }))[0];
    });
    return {
      establishment,
      protocolInfo,
    };
  },
});
