import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import feathers from '@/feathers-client';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    establishment: {
      name: '',
      logo: '',
    },
    snackbar: false,
    snackbarInfo: {
      color: '',
      message: '',
    },
    localities: null,
    zones: null,
    healthInsurances: null,
    shifts: null,
    testTypes: null,
    staff: null,
  },
  mutations: {
    CLEAR_STORE(state: any) {
      state.auth.user = null;
      state.snackbar = false;
      state.snackbarInfo.color = '';
      state.snackbarInfo.message = '';
      localStorage.clear();
    },
    SHOW_SNACKBAR(state: any, { message, color }) {
      state.snackbarInfo.message = message;
      state.snackbarInfo.color = color;
      state.snackbar = true;
    },
    HIDE_SNACKBAR(state: any) {
      state.snackbar = false;
    },
    SET_LOCALITIES(state: any, localities: any) {
      state.localities = localities;
    },
    SET_ZONES(state: any, zones: any) {
      state.zones = zones;
    },
    SET_HEALTH_INSURANCES(state: any, healthInsurances: any) {
      state.healthInsurances = healthInsurances;
    },
    SET_ESTABLISHMENT(state: any, establishment: any) {
      const uint8Array = new Uint8Array(establishment.logo.data);
      establishment.logo = new TextDecoder().decode(uint8Array);
      state.establishment = establishment;
    },
    SET_SHIFTS(state: any, shifts: any) {
      state.shifts = shifts;
    },
    SET_TEST_TYPES(state: any, testTypes: any) {
      state.testTypes = testTypes;
    },
    SET_STAFF(state: any, staff: any) {
      state.staff = staff;
    },
  },
  actions: {
    async setEstablishment({ commit }: any) {
      const establishment = await feathers.service('establishments').get(1);
      commit('SET_ESTABLISHMENT', establishment);
    },
    async getLocalities({ commit }: any) {
      const localitiesData = (
        (await feathers
          .service('localities')
          .find({ query: { $select: ['name', 'municipality'], $sort: { municipality: 1 }, $limit: -1 } })) as Array<any>
      ).map((localityData: any) => {
        return { id: localityData.id, name: `${localityData.name} - ${localityData.municipality}` };
      });

      commit('SET_LOCALITIES', localitiesData);
    },
    async getZones({ commit }: any) {
      const zonesData = await feathers.service('zones').find({ query: { $select: ['name'], $sort: { name: 1 }, $limit: -1 } });
      commit('SET_ZONES', zonesData);
    },
    async getHealthInsurances({ commit, state }: any, enabled = null) {
      const { external } = state.auth.user;
      const healthInsurancesData = await feathers
        .service('health-insurances')
        .find({ query: { searchHealthInsurances: true, external, enabled } });

      commit('SET_HEALTH_INSURANCES', healthInsurancesData);
    },
    async getShifts({ commit }: any, staff?: number) {
      const shiftsData = (await feathers
        .service('shifts')
        .find({ query: { $select: ['name'], $sort: { name: 1 }, $limit: -1 } })) as Array<any>;
      let shiftsInfo = new Array<any>();
      if (staff) {
        for await (const shift of shiftsData) {
          const shiftStaff = (await feathers.service('shifts-staff').find({ query: { shift: shift.id, staff, $limit: -1 } })) as Array<any>;
          if (shiftStaff.length) shiftsInfo.push(shift);
        }
      } else shiftsInfo = shiftsData;

      commit('SET_SHIFTS', shiftsInfo);
    },
    async getTestTypes({ commit }: any) {
      const testTypes = await feathers.service('test-types').find({ query: { $select: ['name'], $sort: { name: 1 }, $limit: -1 } });
      commit('SET_TEST_TYPES', testTypes);
    },
    async getStaff({ commit, state }: any, zone?: number) {
      const { external, username } = state.auth.user;
      let staffData = new Array<any>();

      if (external == true) {
        let staffName = '';
        if (username == 'ivc') staffName = 'ivc';
        else staffName = 'servicios medicos';

        staffData = await feathers.service('staff').find({
          query: {
            $select: ['name', 'last_name'],
            $sort: { name: 1 },
            $limit: -1,
            enabled: true,
            last_name: {
              $ilike: staffName,
            },
          },
        });
      } else {
        staffData = await feathers
          .service('staff')
          .find({ query: { $select: ['name', 'last_name'], $sort: { name: 1 }, $limit: -1, enabled: true } });
      }
      let staffInfo = new Array<any>();
      if (zone) {
        for await (const staff of staffData) {
          const staffZone = (await feathers.service('zones-staff').find({ query: { staff: staff.id, zone, $limit: -1 } })) as Array<any>;
          if (staffZone.length) staffInfo.push(staff);
        }
      } else staffInfo = staffData;
      staffInfo = staffInfo.map((staff) => {
        return { ...staff, name: `${staff.name} ${staff.last_name}` };
      });
      commit('SET_STAFF', staffInfo);
    },
  },
  modules: { auth },
});
