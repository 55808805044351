import feathers from '@/feathers-client';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state: any, user: any) {
      state.user = user;
    },
    CHANGE_PERMISSIONS(state: any, permissions: any) {
      state.permissions = permissions;
    },
  },
  actions: {
    async reAuth({ commit, dispatch }: any) {
      try {
        const response = await feathers.reAuthenticate();
        commit('SET_USER', response.user);

        await feathers.service('last-logins').create({
          username: response.user.username,
          event: 'reAuth',
        });

        return true;
      } catch (error) {
        commit('CLEAR_STORE', null, { root: true });
        return false;
      }
    },
    async login({ commit, dispatch }: any, user: { username: string; password: string }) {
      try {
        const response = await feathers.authenticate({
          strategy: 'local',
          username: user.username,
          password: user.password,
        });

        await feathers.service('last-logins').create({
          username: response.user.username,
          event: 'login',
        });

        commit('SET_USER', response.user);
      } catch (error) {}
    },
    async changeTemporaryPassword({ state, commit, dispatch }: any, newPassword: string) {
      try {
        const response = await feathers.service('users').patch(state.user.id, {
          password: newPassword,
          temporary_password: false,
        });

        commit('SET_USER', response);
      } catch (error) {}
    },
    async logout({ commit, state }: any) {
      try {
        await feathers.service('last-logins').create({
          username: state.user.username,
          event: 'logout',
        });
        await feathers.logout();
        commit('CLEAR_STORE', null, { root: true });
      } catch (error) {}
    },
  },
};
