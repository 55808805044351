












































































































































import { defineComponent, ref, watchEffect } from '@vue/composition-api';
import router from '@/router';
import { useActions, useMutations, useState } from '@u3u/vue-hooks';

export default defineComponent({
  setup() {
    const { user } = useState('auth', ['user']);
    const { establishment } = useState(['establishment']);
    const { logout } = useActions('auth', ['logout']);
    const drawer = ref(null);

    const onLogout = async () => {
      await logout();
      router.push({ name: 'Login' }).catch(() => {});
    };

    return {
      drawer,
      user,
      onLogout,
      establishment,
    };
  },
});
