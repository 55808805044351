
















































































































































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useState, useMutations } from '@u3u/vue-hooks';
import router from '@/router';
import Zones from '@/components/Zones.vue';
import HealthInsurances from '@/components/HealthInsurances.vue';
import Localities from '@/components/Localities.vue';
import Shifts from '@/components/Shifts.vue';
import Staff from '@/components/Staff.vue';
import TestTypes from '@/components/TestTypes.vue';
import moment from 'moment';
import ViewTest from '@/components/ViewTest.vue';
import TestsList from '@/components/TestsList.vue';
import ProtocolsList from '@/components/ProtocolsList.vue';

export default defineComponent({
  props: {
    appointment: Boolean,
  },
  components: {
    ViewTest,
    Zones,
    HealthInsurances,
    Localities,
    Shifts,
    Staff,
    TestTypes,
    TestsList,
    ProtocolsList,
  },
  setup(props, { emit }) {
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const { user } = useState('auth', ['user']);
    const testToDelete = ref(null);
    const deleteDialog = ref(false);
    const tests = ref([]);
    const printDialog = ref(false);
    const printDialogProtocols = ref(false);
    const valid = ref(false);
    const form = ref();
    const users = ref(new Array<any>());
    const loading = ref(false);
    const viewDialog = ref(false);
    const selectedTest = ref('');
    const totalTests = ref(0);
    const options = ref();
    const calendar = ref(false);
    const formattedDate = ref('');
    const excludedProtocols = ref(new Array<any>());
    const searchExcluded = ref(null);
    const searchTest = ref({
      name: '',
      last_name: '',
      dni: '',
      address: '',
      postal_code: '',
      locality: '',
      zone: '',
      health_insurace: '',
      staff: '',
      test_type: '',
      shift: '',
      paid: new Object(),
      result: new Object(),
      date: new Array<any>(),
      created_by: '',
    });

    const headers = ref([
      {
        text: 'Cliente',
        align: 'start',
        value: 'customer_full_name',
      },
      { text: 'Fecha', value: 'date' },
      { text: 'Staff', value: 'staff_full_name' },
      { text: 'Tipo de test', value: 'test_types', sortable: false },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]);
    const numberRule = [
      (value: any) => {
        const pattern = /^(?:\d+,)*\d+$/;
        if (value.length == 0 || pattern.test(value)) {
          return true;
        } else return 'Solo números';
      },
    ];

    const onSelectZoneChanged = (zone: any) => {
      searchTest.value.zone = zone;
    };
    const onSelectLocalityChanged = (locality: any) => {
      searchTest.value.locality = locality;
    };
    const onSelectHealthInsuranceChanged = (healthInsurance: any) => {
      searchTest.value.health_insurace = healthInsurance;
    };
    const onSelectShiftChanged = (shift: any) => {
      searchTest.value.shift = shift;
    };
    const onSelectStaffChanged = (staff: any) => {
      searchTest.value.staff = staff;
    };
    const onSelectTestTypeChanged = (testType: any) => {
      searchTest.value.test_type = testType;
    };

    const updateTest = (item: any) => {
      router.push({ name: 'UpdateTest', params: { id: item.id } });
    };

    const viewTest = (item: any) => {
      selectedTest.value = item.id;
      viewDialog.value = true;
    };

    const deleteTest = async () => {
      loading.value = true;
      await feathers.service('tests').remove(testToDelete.value);
      deleteDialog.value = false;
      testToDelete.value = null;
      await getTests();
      loading.value = false;
    };

    const getTests = async () => {
      loading.value = true;
      const formValid = form.value.validate();
      if (formValid) {
        try {
          const testData = await feathers.service('tests').find({
            query: {
              searchTest: true,
              ...searchTest.value,
              ...options.value,
            },
          });
          testData.data.forEach((a: any) => {
            a.customer_full_name = `${a.customer_name.toUpperCase()} ${a.customer_last_name.toUpperCase()}`;
            a.staff_full_name = `${a.staff_name.toUpperCase()} ${a.staff_last_name.toUpperCase()}`;
            a.date = moment(a.date).format('DD/MM/YYYY');
          });
          totalTests.value = testData.total;
          tests.value = testData.data;
        } catch (err) {}
      }
      loading.value = false;
    };

    watch(options, async (newOptions: any, oldOptions: any) => {
      if (oldOptions) await getTests();
    });

    watch(searchTest.value, () => {
      if (searchTest.value.date.length === 1) {
        formattedDate.value = moment(searchTest.value.date[0]).format('DD/MM/YYYY');
      } else if (searchTest.value.date.length > 1) {
        formattedDate.value = `${moment(searchTest.value.date[0]).format('DD/MM/YYYY')} - ${moment(searchTest.value.date[1]).format(
          'DD/MM/YYYY'
        )}`;
      }
    });

    watch(formattedDate, () => {
      if (formattedDate.value === null) {
        searchTest.value.date = new Array<any>();
      }
    });

    onMounted(async () => {
      if (user.value.super) {
        users.value = (await feathers.service('users').find({ query: { $limit: -1 } })) as Array<any>;
      }
      if (router.currentRoute.params.type === 'unpaid') {
        searchTest.value.paid = 0;
        await getTests();
      } else if (router.currentRoute.params.type === 'results') {
        searchTest.value.result = 2;
        await getTests();
      }
      const dbData = await feathers.service('excluded-protocols').find({ query: { $limit: -1, $sort: { test_test_type: 1 } } });
      excludedProtocols.value = dbData.map((protocol) => {
        return protocol.test_test_type;
      });
    });

    const emitToParent = (item: any) => {
      emit('TestSelected', item);
    };

    const onAddExcludedProtocol = async () => {
      if (numberRule[0](excludedProtocols.value) === true || excludedProtocols.value.length === 0) {
        await feathers.service('excluded-protocols').create(excludedProtocols.value, { query: { addExcludedProtocol: true } });
      }
    };

    const onListPrinted = (printedData: boolean) => {
      printDialog.value = false;
      if (!printedData) SHOW_SNACKBAR({ message: 'No hay tests para los parámetros seleccionados 😕', color: 'error' });
    };

    const onProtocolsPrinted = (printedData: boolean) => {
      printDialogProtocols.value = false;
      if (!printedData) SHOW_SNACKBAR({ message: 'No hay protocolos para los parámetros seleccionados 😕', color: 'error' });
    };

    return {
      numberRule,
      onAddExcludedProtocol,
      onSelectZoneChanged,
      onSelectLocalityChanged,
      onSelectHealthInsuranceChanged,
      onSelectShiftChanged,
      onSelectStaffChanged,
      onSelectTestTypeChanged,
      headers,
      tests,
      getTests,
      valid,
      form,
      loading,
      searchTest,
      updateTest,
      viewTest,
      deleteTest,
      emitToParent,
      deleteDialog,
      selectedTest,
      viewDialog,
      totalTests,
      options,
      calendar,
      formattedDate,
      testToDelete,
      user,
      users,
      excludedProtocols,
      searchExcluded,
      printDialog,
      printDialogProtocols,
      onListPrinted,
      onProtocolsPrinted,
    };
  },
});
