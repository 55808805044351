var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-html2pdf',{ref:"htmlToPrint",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"pdf-quality":2,"filename":_vm.title,"manual-pagination":true,"pdf-format":"letter","pdf-orientation":"portrait","pdf-content-width":"720px","html-to-pdf-options":{
    margin: [40, 40, 40, 40],
    filename: (_vm.title + ".pdf"),
    jsPDF: {
      unit: 'px',
      format: 'letter',
      orientation: 'portrait',
      hotfixes: ['px_scaling'],
    },
  }}},[_c('section',{staticStyle:{"font-size":"14px","font-family":"'Roboto', sans-serif"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item",staticStyle:{"height":"90px","font-weight":"bold"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[(_vm.date.length === 1)?_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(("Protocolos del " + (_vm.moment(_vm.date[0]).format('DD/MM/YYYY')))))]):(_vm.date.length > 1)?_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(("Protocolos del " + (_vm.moment(_vm.date[0]).format('DD/MM/YYYY')) + " al " + (_vm.moment(_vm.date[1]).format('DD/MM/YYYY')))))]):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("Paciente")])]),_c('v-col',[_c('span',[_vm._v("DNI/PASAPORTE")])]),_c('v-col',[_c('span',[_vm._v("Tipo de test")])]),_c('v-col',[_c('span',[_vm._v("Resultado")])]),_c('v-col',[_c('span',[_vm._v("Número de protocolo")])])],1)],1)],1),_vm._l((_vm.listInfo),function(protocol,index){return _c('div',{key:index},[(index != 0 && index % 14 == 0)?_c('div',{staticClass:"html2pdf__page-break"}):_vm._e(),_c('section',{staticClass:"pdf-item",staticStyle:{"min-height":"50px"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('span',[_vm._v(_vm._s(protocol.lastName)+" "+_vm._s(protocol.name))])]),_c('v-col',[_c('span',[_vm._v(_vm._s(protocol.dni))])]),_c('v-col',[_c('span',[_vm._v(_vm._s(protocol.testType))])]),_c('v-col',[_c('span',[_vm._v(_vm._s(protocol.result === true ? protocol.testType === 'PCR' ? 'Detectable' : 'Positivo' : protocol.result === false ? protocol.testType === 'PCR' ? 'No detectable' : 'Negativo' : 'Sin resultado'))])]),_c('v-col',[_c('span',[_vm._v(_vm._s(protocol.protocol))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-divider')],1)],1)],1)],1)])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }