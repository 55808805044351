






import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import StaffForm from '@/components/StaffForm.vue';
import moment from 'moment';

export default defineComponent({
  props: {
    staffId: Number,
  },
  components: {
    StaffForm,
  },
  setup(props) {
    const staffInfo = ref({
      name: '',
      last_name: '',
      locality: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      phone: '',
      email: '',
      shifts: null,
      zones: '',
    });

    const getStaffInfo = async () => {
      try {
        let staff = await feathers.service('staff').get(props.staffId);
        if (staff) {
          const zones = (await feathers
            .service('zones-staff')
            .find({ query: { staff: props.staffId, $select: ['zone'], $limit: -1 } })) as Array<any>;
          const shifts = (await feathers
            .service('shifts-staff')
            .find({ query: { staff: props.staffId, $select: ['shift'], $limit: -1 } })) as Array<any>;

          staff.zones = zones.map((zone: any) => {
            return zone.zone;
          });
          staff.shifts = shifts.map((shift: any) => {
            return shift.shift;
          });

          staff = JSON.parse(JSON.stringify(staff).replace(/:null/gi, ':""'));
          staffInfo.value = { ...staff };
        }
      } catch (error) {}
    };

    onMounted(async () => {
      await getStaffInfo();
    });

    return {
      staffInfo,
    };
  },
});
