















































































































































































































































import { defineComponent, ref, onMounted, watch, watchEffect } from '@vue/composition-api';
import feathers from '@/feathers-client';
import moment from 'moment';
import { useMutations, useState } from '@u3u/vue-hooks';
import Roster from '@/components/Roster.vue';
import router from '@/router';

export default defineComponent({
  props: {
    staffId: Number,
    date: String,
  },
  components: { Roster },
  setup(props, { emit }) {
    const showCompany = ref(false);
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const { user } = useState('auth', ['user']);
    const printDialog = ref(false);
    const selectedTest = ref();
    const staffDate = ref(moment().format('YYYY-MM-DD'));
    const latestUpdateRoster = ref(localStorage.getItem('latestUpdateRoster'));
    const staff = ref({
      id: '',
      name: '',
      last_name: '',
    });
    const rosterInfo = ref({
      groupedMorning: new Array<any>(),
      groupedAfternoon: new Array<any>(),
    });

    const getRoster = async (date?: string) => {
      const info = await feathers.service('tests').find({
        query: {
          roster: true,
          staff: staff.value.id,
          date: date ? date : moment().format('YYYY-MM-DD'),
          staffPage: true,
          showCompany: showCompany.value,
        },
      });

      if (info.groupedMorning || info.groupedMorning) {
        rosterInfo.value.groupedMorning = Object.entries(info.groupedMorning);
        localStorage.setItem('groupedMorning', JSON.stringify(rosterInfo.value.groupedMorning));

        rosterInfo.value.groupedAfternoon = Object.entries(info.groupedAfternoon);
        localStorage.setItem('groupedAfternoon', JSON.stringify(rosterInfo.value.groupedAfternoon));

        localStorage.setItem('latestUpdateRoster', new Date().getTime().toString());
        latestUpdateRoster.value = localStorage.getItem('latestUpdateRoster')!;
      }
    };

    const getStaff = async () => {
      if (props.staffId !== -1) {
        const staffId = props.staffId ? props.staffId : user.value.staff;
        staff.value = await feathers.service('staff').get(staffId);
      } else
        staff.value = {
          id: '',
          name: '',
          last_name: '',
        };
    };

    onMounted(async () => {
      await getStaff();
      if (
        !localStorage.getItem('groupedMorning') ||
        !localStorage.getItem('groupedAfternoon') ||
        (localStorage.getItem('latestUpdateRoster') &&
          new Date().getTime() - Number(localStorage.getItem('latestUpdateRoster')) >= 43200000)
      ) {
        await getRoster(props.date);
      } else {
        rosterInfo.value.groupedMorning = JSON.parse(localStorage.getItem('groupedMorning')!);
        rosterInfo.value.groupedAfternoon = JSON.parse(localStorage.getItem('groupedAfternoon')!);
      }
    });

    const onClickUpMorning = (index: number) => {
      const element = rosterInfo.value.groupedMorning[index];
      rosterInfo.value.groupedMorning.splice(index, 1);
      rosterInfo.value.groupedMorning.splice(index - 1, 0, element);
      localStorage.setItem('groupedMorning', JSON.stringify(rosterInfo.value.groupedMorning));
    };

    const onClickDownMorning = (index: number) => {
      const element = rosterInfo.value.groupedMorning[index];
      rosterInfo.value.groupedMorning.splice(index, 1);
      rosterInfo.value.groupedMorning.splice(index + 1, 0, element);
      localStorage.setItem('groupedMorning', JSON.stringify(rosterInfo.value.groupedMorning));
    };

    const onClickUpAfternoon = (index: number) => {
      const element = rosterInfo.value.groupedAfternoon[index];
      rosterInfo.value.groupedAfternoon.splice(index, 1);
      rosterInfo.value.groupedAfternoon.splice(index - 1, 0, element);
      localStorage.setItem('groupedAfternoon', JSON.stringify(rosterInfo.value.groupedAfternoon));
    };

    const onClickDownAfternoon = (index: number) => {
      const element = rosterInfo.value.groupedAfternoon[index];
      rosterInfo.value.groupedAfternoon.splice(index, 1);
      rosterInfo.value.groupedAfternoon.splice(index + 1, 0, element);
      localStorage.setItem('groupedAfternoon', JSON.stringify(rosterInfo.value.groupedAfternoon));
    };

    const onRosterPrinted = (printedData: boolean) => {
      printDialog.value = false;
      if (!printedData) SHOW_SNACKBAR({ message: 'No se encontraron turnos para el dia y staff seleccionados 😕', color: 'error' });
    };

    const onClickPrint = () => {
      printDialog.value = true;
    };

    watchEffect(async () => {
      if (props.date) {
        await getStaff();
        await getRoster(props.date);
      }
    });

    const onChangeSwitch = async () => {
      await getRoster(props.date);
      emit('showCompanyChange', showCompany.value);
    };

    return {
      showCompany,
      onChangeSwitch,
      user,
      moment,
      staff,
      rosterInfo,
      selectedTest,
      onClickUpMorning,
      onClickDownMorning,
      onClickUpAfternoon,
      onClickDownAfternoon,
      onRosterPrinted,
      onClickPrint,
      getRoster,
      latestUpdateRoster,
      printDialog,
      staffDate,
    };
  },
});
