






import { defineComponent, ref, onMounted } from '@vue/composition-api';
import feathers from '@/feathers-client';
import CustomerForm from '@/components/CustomerForm.vue';
import moment from 'moment';

export default defineComponent({
  props: {
    customerId: Number,
  },
  components: {
    CustomerForm,
  },
  setup(props) {
    const customerInfo = ref({
      name: '',
      last_name: '',
      dni: '',
      date_of_birth: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      country: false,
      details: '',
      phone: '',
      email: '',
      health_insurance: '',
      health_insurance_number: '',
      locality: '',
    });

    const getCustomerInfo = async () => {
      try {
        let customer = await feathers.service('customers').get(props.customerId);
        if (customer) {
          if (customer.date_of_birth) customer.date_of_birth = moment(customer.date_of_birth).format('YYYY-MM-DD');
          customer = JSON.parse(JSON.stringify(customer).replace(/:null/gi, ':""'));
          customerInfo.value = { ...customer };
        }
      } catch (error) {}
    };

    onMounted(async () => {
      await getCustomerInfo();
    });

    return {
      customerInfo,
    };
  },
});
