

















































































































































































































































































































































































































































































































































































import { defineComponent, ref, onMounted, watch } from '@vue/composition-api';
import GoBackButton from '@/components/GoBackButton.vue';
import feathers from '@/feathers-client';
import moment from 'moment';
import { useActions, useState } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    testInfo: null,
    viewing: Boolean,
    updating: Boolean,
  },
  components: {
    GoBackButton,
  },
  setup(props, { emit }) {
    const { testTypes, healthInsurances, zones, staff, shifts, localities } = useState([
      'testTypes',
      'healthInsurances',
      'zones',
      'staff',
      'shifts',
      'localities',
    ]);
    const { user } = useState('auth', ['user']);
    const { getStaff, getShifts, getHealthInsurances } = useActions(['getStaff', 'getShifts', 'getHealthInsurances']);
    const selectedTestTypes = ref(new Array<number>());
    const multipleCustomers = ref(new Array<any>());
    const searchParameter = ref('');
    const testSameHour = ref(0);
    const form = ref();
    const valid = ref(false);
    const loading = ref(false);
    const calendar = ref(false);
    const testQuantity = ref('');
    const formattedDate = ref('');
    const dialog = ref(false);
    const existingTest = ref(
      new Array<{
        name: string;
        last_name: string;
        dni: string;
        date: string;
      }>()
    );
    const numberRule = [
      (value: string) => !!value || 'Este campo es obligatorio',
      (value: string) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || 'Solo números';
      },
    ];
    const dniRule = [
      (value: string) => !!value || 'Este campo es obligatorio',
      (value: string) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || 'Solo números';
      },
      (value: string) => value.length <= 8 || 'Máximo 8 caracteres',
    ];

    const emailRule = [
      (value: string) => !!value || 'Este campo es obligatorio',
      (value: string) => {
        const pattern =
          /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        return pattern.test(value) || 'Formato de correo electrónico inválido';
      },
    ];
    const requiredRule = [(v: string) => !!v || 'Este campo es obligatorio'];
    const requiredRuleArray = [(v: Array<number>) => !!v.length || 'Este campo es obligatorio'];

    const customerInfo = ref({
      id: null,
      name: '',
      last_name: '',
      dni: '',
      date_of_birth: '',
      address: '',
      address_number: '',
      floor: '',
      department: '',
      postal_code: '',
      country: false,
      details: '',
      phone: '',
      email: '',
      health_insurance: 0,
      health_insurance_number: '',
      locality: 0,
      family: new Array<any>(),
    });

    const emitToParent = async () => {
      loading.value = true;
      const formValid = form.value.validate();
      try {
        if (formValid) {
          existingTest.value = await feathers
            .service('tests')
            .find({ query: { findExistingTest: true, testInfo: props.testInfo, customerInfo: customerInfo.value } });

          const formData = {
            testInfo: props.testInfo,
            customerInfo: customerInfo.value,
          };

          if (existingTest.value.length) {
            dialog.value = true;
          } else {
            emit('submitTestForm', formData);
          }
        }
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      await getStaff();
      if (props.viewing) await getHealthInsurances();
      else await getHealthInsurances(true);
    });

    const onChangeZone = async (zone: number) => {
      await getStaff(zone);
    };

    const onChangeStaff = async (staff: number) => {
      await getShifts(staff);
    };

    const onClickDate = async (selectedDate: any) => {
      const testsOnDate = await feathers.service('tests').find({ query: { staff: props.testInfo.staff, date: selectedDate, $limit: 0 } });
      testQuantity.value = testsOnDate.total;
    };

    const onChangeResult = (result: any) => {
      props.testInfo.result = result;
    };

    const onChangeTestType = (testTypes: any, index?: any) => {
      if (index != undefined) {
        customerInfo.value.family[index].test_type = testTypes.map((testType: any) => {
          const existing = customerInfo.value.family[index].test_type.findIndex((i) => i.test_type == testType);
          return {
            id: existing != -1 ? customerInfo.value.family[index].test_type[existing].id : null,
            test_type: testType,
            paid: existing != -1 ? customerInfo.value.family[index].test_type[existing].paid : false,
            result: existing != -1 ? customerInfo.value.family[index].test_type[existing].result : null,
            details: existing != -1 ? customerInfo.value.family[index].test_type[existing].details : null,
          };
        });
      } else {
        props.testInfo.test_type = testTypes.map((testType: any) => {
          const existing = props.testInfo.test_type.findIndex((i) => i.test_type == testType);
          return {
            id: existing != -1 ? props.testInfo.test_type[existing].id : null,
            test_type: testType,
            paid: existing != -1 ? props.testInfo.test_type[existing].paid : false,
            result: existing != -1 ? props.testInfo.test_type[existing].result : null,
            details: existing != -1 ? props.testInfo.test_type[existing].details : null,
          };
        });
      }
    };

    const onAddPerson = () => {
      customerInfo.value.family.push({
        name: '',
        last_name: '',
        dni: '',
        date_of_birth: '',
        test_type: new Array<any>(),
      });
    };

    const onDeletePerson = (index: number) => {
      customerInfo.value.family.splice(index, 1);
    };

    const getCustomer = async (customerId?: number) => {
      loading.value = true;
      try {
        if (searchParameter.value) {
          let customer;

          if (customerId) {
            customer = [await feathers.service('customers').get(customerId)];
          } else {
            customer = (await feathers
              .service('customers')
              .find({ query: { searchCustomerTest: true, searchParameter: searchParameter.value, $limit: -1 } })) as Array<any>;
          }

          if (customer.length && customer.length === 1) {
            multipleCustomers.value = new Array<any>();
            if (customer[0].date_of_birth) customer[0].date_of_birth = moment(customer[0].date_of_birth).format('YYYY-MM-DD');
            customer[0] = JSON.parse(JSON.stringify(customer[0]).replace(/:null/gi, ':""'));
            customer[0].family = new Array<any>();
            customerInfo.value = customer[0];
            props.testInfo.customer = customerInfo.value.id;
            const zone = await getZone(customer[0].coordinates.x, customer[0].coordinates.y);
            if (zone) props.testInfo.zone = zone.id;
          } else if (customer.length && customer.length > 1) {
            customerInfo.value.id = null;
            multipleCustomers.value = customer;
          } else {
            multipleCustomers.value = new Array<any>();
            Object.keys(customerInfo.value).forEach((i) =>
              i !== 'dni' ? (customerInfo.value[i] = '') : (customerInfo.value[i] = customerInfo.value.dni)
            );
            customerInfo.value.family = new Array<any>();
          }
        }
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    const getZone = async (latitude: any, longitude: any) => {
      return await feathers.service('polygons').find({ query: { getZone: true, latitude, longitude } });
    };

    watch(props, async () => {
      if (props.testInfo.date) formattedDate.value = moment(props.testInfo.date).format('DD/MM/YYYY');
      if ((props.viewing || props.updating) && !customerInfo.value.dni) {
        Object.keys(props.testInfo).forEach((i) => {
          if (i === 'id') {
          } else if (i === 'customer') customerInfo.value.id = props.testInfo[i];
          else {
            customerInfo.value[i] = props.testInfo[i];
          }
        });
        const customer = await feathers.service('customers').get(props.testInfo.customer);
        customerInfo.value.name = customer.name;
        customerInfo.value.last_name = customer.last_name;
        if (customer.date_of_birth) customerInfo.value.date_of_birth = moment(customer.date_of_birth).format('YYYY-MM-DD');
        customerInfo.value.dni = customer.dni;
        props.testInfo.date = moment(props.testInfo.date).format('YYYY-MM-DD');
        onClickDate(props.testInfo.date);
        selectedTestTypes.value = props.testInfo.test_type.map((testType) => {
          return { ...testType, id: testType.test_type };
        });
      }
    });

    const onHourChanged = async () => {
      testSameHour.value = (
        await feathers
          .service('tests')
          .find({ query: { staff: props.testInfo.staff, date: props.testInfo.date, hour: props.testInfo.hour, $limit: 0 } })
      ).total;
    };

    return {
      user,
      searchParameter,
      selectedTestTypes,
      multipleCustomers,
      healthInsurances,
      zones,
      staff,
      shifts,
      localities,
      customerInfo,
      requiredRule,
      getCustomer,
      numberRule,
      dniRule,
      emailRule,
      form,
      valid,
      calendar,
      testQuantity,
      formattedDate,
      emitToParent,
      onChangeZone,
      onChangeStaff,
      onChangeTestType,
      onChangeResult,
      onClickDate,
      onAddPerson,
      onDeletePerson,
      requiredRuleArray,
      loading,
      moment,
      testTypes,
      onHourChanged,
      testSameHour,
      existingTest,
      dialog,
    };
  },
});
