




















import { defineComponent, ref, watch } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    outlined: Boolean,
    dense: Boolean,
    icon: String,
    rules: Array,
    selectedLocality: null,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const value = ref(0);
    const { localities } = useState(['localities']);

    const emitToParent = (event: any) => {
      emit('selectLocalityChanged', event);
    };

    watch(props, () => {
      value.value = props.selectedLocality;
    });

    return {
      emitToParent,
      value,
      localities,
    };
  },
});
