export function normalize(string: string) {
  string = string.toLowerCase();
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function capitalize(string: string) {
  try {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (error) {
    return '';
  }
}

export function printElem(element: string, title: string) {
  const mywindow = window.open('', 'PRINT', 'height=400,width=600');
  if (mywindow !== null) {
    mywindow.document.write(`<html><head><title>${title}</title>`);
    mywindow.document.write('</head><body><div id="app"><v-app><v-main>');
    const elementById = document.getElementById(element);
    if (elementById) mywindow.document.write(elementById.innerHTML);
    mywindow.document.write('</v-main></v-app></div></body></html>');
    let link = mywindow.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', 'https://cdn.quilljs.com/1.0.0/quill.core.css');
    mywindow.document.getElementsByTagName('head')[0].appendChild(link);
    link = mywindow.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
    mywindow.document.getElementsByTagName('head')[0].appendChild(link);
    link = mywindow.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', 'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css');
    mywindow.document.getElementsByTagName('head')[0].appendChild(link);
    const style = mywindow.document.createElement('style');
    style.textContent = `
    .textsize {
      font-size: 80%;
    }
    .divider {
      border-width: 1px !important;
      border-color: black !important;
      height: 100%;
    }
    .sendToBottom {

      bottom: 0;
      width: 100%;

    }
    .parent {

      height: 100%;
  
    }
    .topito {
      margin-bottom: 9cm;
    }
     `;
    mywindow.document.getElementsByTagName('body')[0].appendChild(style);
    let script = mywindow.document.createElement('script');
    script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js');
    mywindow.document.getElementsByTagName('body')[0].appendChild(script);
    script = mywindow.document.createElement('script');
    script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js');
    mywindow.document.getElementsByTagName('body')[0].appendChild(script);
    setTimeout(() => {
      const script = mywindow.document.createElement('script');
      script.textContent = `new Vue({
    el: '#app',
    vuetify: new Vuetify(),
  })`;
      mywindow.document.getElementsByTagName('body')[0].appendChild(script);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
      return true;
    }, 1000);
  }
}
