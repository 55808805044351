import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';

const app = feathers();
const restClient = rest(`${process.env.VUE_APP_SOCKET_URL}`);
app.configure(restClient.fetch(window.fetch));
app.configure(auth());

export default app;
