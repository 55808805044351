var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h5"},[_vm._v("Servicios de salud")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"10","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Agregar servicio de salud","hint":"Puede presionar enter para agregar"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onAddHealthInsurance.apply(null, arguments)}},model:{value:(_vm.healthInsurance),callback:function ($$v) {_vm.healthInsurance=$$v},expression:"healthInsurance"}})],1),_c('v-col',{attrs:{"sm":"2","cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onAddHealthInsurance}},[_vm._v("Guardar")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.healthInsurances,"hide-default-footer":"","disable-pagination":true,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... por favor espere"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"save":function($event){return _vm.onSaveChange(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Modificar","single-line":"","hint":"Presione enter para confirmar"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('span',[_vm._v("Si")]):_c('span',[_vm._v("No")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.onDisableHealthInsurance(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Deshabilitar")])]):_vm._e(),(!item.enabled)?_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.onEnableHealthInsurance(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-marked-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Habilitar")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }