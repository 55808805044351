










import { defineComponent, ref } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';
import Dashboard from '@/components/Dashboard.vue';
import ViewRoster from '@/components/ViewRoster.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Dashboard,
    ViewRoster,
  },
  setup() {
    const { user } = useState('auth', ['user']);
    return { user };
  },
});
