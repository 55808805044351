

































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import feathers from '@/feathers-client';

export default defineComponent({
  props: {
    protocolInfo: null,
    protocolList: null,
  },
  components: { VueHtml2pdf, QrcodeVue },
  setup(props, { emit }) {
    const uuid = ref('');
    const dataToPrint = ref({
      protocol: '',
      date: '',
      hour: '',
      dni: '',
      dateOfBirth: '',
      fullName: '',
      testType: '',
      result: null,
      updatedAt: '',
      customSelect: false,
    });
    const vueHost = ref(process.env.VUE_APP_HOST);
    const htmlToPrint = ref();
    const title = ref('');

    const print = async () => {
      try {
        uuid.value = await feathers.service('qr-codes').find({
          query: {
            searchQrCode: true,
            protocol: dataToPrint.value.protocol,
            date:
              dataToPrint.value.customSelect && dataToPrint.value.date
                ? moment(dataToPrint.value.date).format('DD/MM/YYYY')
                : moment(dataToPrint.value.updatedAt).format('DD/MM/YYYY'),
            hour:
              dataToPrint.value.customSelect && dataToPrint.value.hour
                ? dataToPrint.value.hour
                : moment(dataToPrint.value.updatedAt).format('HH:mm'),
            dni: dataToPrint.value.dni,
            dateOfBirth: dataToPrint.value.dateOfBirth ? moment(dataToPrint.value.dateOfBirth).format('DD/MM/YYYY') : '--',
            customer: dataToPrint.value.fullName,
            age: dataToPrint.value.dateOfBirth ? moment().diff(dataToPrint.value.dateOfBirth, 'years') : '--',
            testType: dataToPrint.value.testType === 'PCR' ? 'RT-PCR - Real time' : 'Covid-19 Test De Antigenos / COVID-19 Antigen Test',
            result:
              dataToPrint.value.result == true
                ? dataToPrint.value.testType == 'PCR'
                  ? 'DETECTABLE / DETECTABLE'
                  : 'POSITIVO / POSITIVE'
                : dataToPrint.value.testType == 'PCR'
                ? 'NO DETECTABLE / NOT DETECTABLE'
                : 'NEGATIVO / NEGATIVE',
          },
        });
        await htmlToPrint.value.generatePdf();
      } catch (error) {}
    };

    onMounted(async () => {
      let printedData = true;
      if (props.protocolInfo && props.protocolInfo.protocol) {
        dataToPrint.value = props.protocolInfo;
        title.value = `${dataToPrint.value.fullName} ${dataToPrint.value.testType === 'PCR' ? 'PCR' : 'ATG'}`;
        await print();
      } else if (props.protocolList.length) {
        for await (const protocolInfo of props.protocolList) {
          dataToPrint.value = protocolInfo;
          title.value = `${dataToPrint.value.fullName} ${dataToPrint.value.testType === 'PCR' ? 'PCR' : 'ATG'}`;
          await print();
        }
      } else printedData = false;
      emit('printed', printedData);
    });
    return {
      vueHost,
      uuid,
      htmlToPrint,
      moment,
      title,
      dataToPrint,
    };
  },
});
