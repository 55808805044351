


























import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useActions, useState } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    outlined: Boolean,
    dense: Boolean,
    icon: String,
    rules: Array,
    selectedHealthInsurance: null,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const value = ref(0);
    const { getHealthInsurances } = useActions(['getHealthInsurances']);
    const { healthInsurances } = useState(['healthInsurances']);

    const emitToParent = (event: any) => {
      emit('selectHealthInsuranceChanged', event);
    };

    onMounted(async () => {
      await getHealthInsurances();
    });

    watch(props, () => {
      if (props.selectedHealthInsurance) value.value = props.selectedHealthInsurance;
    });

    return {
      emitToParent,
      value,
      healthInsurances,
    };
  },
});
