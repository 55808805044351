



























import { defineComponent, onMounted, ref, watch, watchEffect } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';
import feathers from '@/feathers-client';

export default defineComponent({
  props: {
    outlined: Boolean,
    dense: Boolean,
    icon: String,
    rules: Array,
    selectedStaff: null,
    selectedZone: null,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const value = ref(null);
    const staff = ref(new Array<any>());
    const { user } = useState('auth', ['user']);
    const emitToParent = (event: any) => {
      emit('selectStaffChanged', event);
    };

    onMounted(async () => {
      if (!props.selectedZone) {
        const { external, username } = user.value;
        let staffName = '';
        let filter: { last_name: { $ilike: string } } | null = null;

        if (external == true) {
          if (username == 'ivc') staffName = 'ivc';
          else staffName = 'servicios medicos';

          filter = {
            last_name: {
              $ilike: staffName,
            },
          };
        }

        staff.value = (await feathers.service('staff').find({ query: { enabled: true, $limit: -1, ...filter } })) as Array<any>;
      }
    });

    watchEffect(async () => {
      const { external, username } = user.value;
      let staffName = '';
      let filter: { last_name: { $ilike: string } } | null = null;

      if (external == true) {
        if (username == 'ivc') staffName = 'ivc';
        else staffName = 'servicios medicos';

        filter = {
          last_name: {
            $ilike: staffName,
          },
        };
      }
      if (props.selectedZone && !props.readonly) {
        staff.value = [];
        const zonesStaff = (await feathers
          .service('zones-staff')
          .find({ query: { zone: props.selectedZone, $limit: -1, ...filter } })) as Array<any>;
        const staffIds = zonesStaff.map(({ staff }) => {
          return staff;
        });
        if (staffIds.length)
          staff.value = (await feathers.service('staff').find({
            query: {
              id: { $in: staffIds },
              enabled: true,
              $limit: -1,
              $sort: {
                name: 1,
              },
            },
          })) as Array<any>;
      } else if (!props.readonly) {
        staff.value = (await feathers.service('staff').find({ query: { enabled: true, $limit: -1, ...filter } })) as Array<any>;
      }
    });

    watch(props, () => {
      if (props.selectedStaff) value.value = props.selectedStaff;
    });

    return {
      emitToParent,
      value,
      staff,
    };
  },
});
