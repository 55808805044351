





























import { defineComponent, ref, watch, watchEffect } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';

export default defineComponent({
  props: {
    outlined: Boolean,
    dense: Boolean,
    icon: String,
    rules: Array,
    multiple: Boolean,
    chips: Boolean,
    selectedTestType: null,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const value = ref(0);
    const { testTypes } = useState(['testTypes']);

    const emitToParent = (event: any) => {
      emit('selectTestTypeChanged', event);
    };

    watch(props, () => {
      value.value = props.selectedTestType;
    });

    return {
      emitToParent,
      value,
      testTypes,
    };
  },
});
