











































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { useMutations } from '@u3u/vue-hooks';

export default defineComponent({
  setup() {
    const { SHOW_SNACKBAR } = useMutations(['SHOW_SNACKBAR']);
    const healthInsurance = ref('');
    const search = ref('');
    const loading = ref(false);
    const healthInsurances = ref(
      new Array<{
        id: number;
        name: string;
      }>()
    );
    const headers = ref([
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]);

    const getHealthInsurances = async () => {
      try {
        loading.value = true;
        healthInsurances.value = await feathers.service('health-insurances').find({ query: { $sort: { name: 1 }, $limit: -1 } });
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      await getHealthInsurances();
    });

    const onSaveChange = async (item: any) => {
      try {
        await feathers.service('health-insurances').patch(item.id, { name: item.name });
        SHOW_SNACKBAR({ message: `Se actualizo ${item.name.toUpperCase()} con éxito 😄`, color: 'success' });
      } catch (error) {
        SHOW_SNACKBAR({ message: `Error al actualizar ${item.name.toUpperCase()} 😕`, color: 'error' });
      }
    };

    const onAddHealthInsurance = async () => {
      if (healthInsurance.value) {
        try {
          await feathers.service('health-insurances').create({
            name: healthInsurance.value,
          });
          await getHealthInsurances();
          SHOW_SNACKBAR({ message: `Se agrego ${healthInsurance.value.toUpperCase()} con éxito 😄`, color: 'success' });
        } catch (error) {
          SHOW_SNACKBAR({ message: `Error al agregar ${healthInsurance.value.toUpperCase()} 😕`, color: 'error' });
        } finally {
          healthInsurance.value = '';
        }
      }
    };

    const onDisableHealthInsurance = async (item: any) => {
      try {
        await feathers.service('health-insurances').patch(item.id, {
          enabled: false,
        });
        await getHealthInsurances();
        SHOW_SNACKBAR({ message: `Se deshabilitó ${item.name.toUpperCase()} con éxito 😄`, color: 'success' });
      } catch (error) {
        SHOW_SNACKBAR({ message: `Error al deshabilitar ${item.name.toUpperCase()} 😕`, color: 'error' });
      }
    };

    const onEnableHealthInsurance = async (item: any) => {
      try {
        await feathers.service('health-insurances').patch(item.id, {
          enabled: true,
        });
        await getHealthInsurances();
        SHOW_SNACKBAR({ message: `Se habilitó ${item.name.toUpperCase()} con éxito 😄`, color: 'success' });
      } catch (error) {
        SHOW_SNACKBAR({ message: `Error al habilitar ${item.name.toUpperCase()} 😕`, color: 'error' });
      }
    };

    return {
      search,
      loading,
      healthInsurances,
      headers,
      onSaveChange,
      onAddHealthInsurance,
      healthInsurance,
      onDisableHealthInsurance,
      onEnableHealthInsurance,
    };
  },
});
