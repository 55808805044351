































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import feathers from '@/feathers-client';
import { printElem } from '@/utils';
import { useState } from '@u3u/vue-hooks';
import moment from 'moment';

export default defineComponent({
  props: {
    staff: null,
    date: String,
  },
  setup(props, { emit }) {
    const { establishment } = useState(['establishment']);
    const rosterInfo = ref({
      groupedMorning: new Array<any>(),
      groupedAfternoon: new Array<any>(),
    });
    const setRosterInfo = async () => {
      rosterInfo.value.groupedMorning = await JSON.parse(localStorage.getItem('groupedMorning')!);
      rosterInfo.value.groupedAfternoon = await JSON.parse(localStorage.getItem('groupedAfternoon')!);
    };
    onMounted(async () => {
      let printedData = false;
      if (JSON.parse(localStorage.getItem('groupedMorning')!).length || JSON.parse(localStorage.getItem('groupedAfternoon')!).length) {
        await setRosterInfo();
        const title = props.staff.last_name ? `Hoja de ruta de ${props.staff.last_name} ${props.staff.name}` : 'Todos los tests del día';
        await printElem('printMe', title);
        printedData = true;
      }
      emit('printed', printedData);
    });
    return {
      establishment,
      moment,
      rosterInfo,
    };
  },
});
